import {UntypedFormGroup} from '@angular/forms';


export function pwdMatchValidator(form: UntypedFormGroup): { [key: string]: boolean } {

  if (!form || !form.controls || form.get('password').value.lenght < 6 || form.get('confirmPassword').value < 1) {
    return null;
  }
  return form.get('password').value === form.get('confirmPassword').value ? null : {pwdMismatch: true};

}
