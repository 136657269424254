import {Injectable} from '@angular/core';
import {JwtHelperService} from '@auth0/angular-jwt';
import {LocalStorageService} from 'ngx-webstorage';
import {HttpClient, HttpParams, HttpUrlEncodingCodec} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {map} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {InviteRequest, RegisterUser, ResetPassword, User} from '../interfaces/user';
import {notificationMessage, NotificationService} from './notification.service';
import {ResetPasswordComponent} from '../../guest/reset-password/reset-password.component';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(private jwtHelper: JwtHelperService,
              private http: HttpClient,
              private notificationService: NotificationService,
              private localStorage: LocalStorageService) {
  }

  public get user(): User {
    return this.localStorage.retrieve('user') ?? null;
  }

  public set user(user: User) {
    this.localStorage.store('user', user);
  }

  public get token() {
    return this.localStorage.retrieve('accessToken') ?? null;
  }

  public login(data): Observable<User> {
    return this.http.post<User>(`${environment.apiUrl}/Account/Login`, data).pipe(
      map((response: any) => {
        return this.updateUserAndToken(response);
      })
    );
  }

  public register(data: RegisterUser, token: string): Observable<any> {
    return this.http.post(`${environment.apiUrl}/Account/Register/${token}`, data);
  }

  public inviteUser(data: InviteRequest): Observable<User> {
    return this.http.post<User>(`${environment.apiUrl}/Account/Invite`, data);
  }

  public updateProfile(data: User): Observable<User> {
    delete data.id;
    return this.http.put<User>(`${environment.apiUrl}/Users`, data).pipe(
      map((response: any) => {
        return this.updateUserAndToken(response.login);
      })
    );
  }

  public logout(): void {
    this.localStorage.clear('accessToken');
    this.localStorage.clear('user');
    this.localStorage.clear('organization');
    this.notificationService.sendMessage(notificationMessage.logout);
  }

  private updateUserAndToken(response): User {
    this.localStorage.store('accessToken', response.accessToken);
    const jwtData = this.jwtHelper.decodeToken(response.accessToken);
    const user = this.mapUserData(jwtData);
    this.user = user;
    this.notificationService.sendMessage(notificationMessage.login);
    return user;
  }

  private mapUserData(jwtData: any): User {
    const user = {
      firstName: jwtData.given_name,
      lastName: jwtData.family_name,
      email: jwtData.email,
      role: jwtData.role,
      id: jwtData.nameid,
      userName: jwtData.unique_name,
      language: jwtData.language,
      organization: {
        id: jwtData.org_id,
        name: jwtData.org_name,
        avatarFileName: jwtData.org_avatar
      }
    };

    if (user.role === 'SYS_ADMIN') {
      delete user.organization;
    }

    return user;
  }

  public resetPassword(newPassword: string, email: string, token: string): Observable<any>{
    const data: ResetPassword = {newPassword, email, token};
    console.log(data);
    return this.http.put(`${environment.apiUrl}/Account/ResetPassword`, data);
  }

  public requestResetPassword(email: string): Observable<any>{
    return this.http.get(`${environment.apiUrl}/Account/RequestResetPassword?email=${email}`);
  }
}
