
<perfect-scrollbar>
  <div class="container">
    <div class="row justify-content-center ">
      <div class="col-7 p-0 text-center logo">
        <img src="../../../assets/images/logo-full.png" width="241" height="80">
      </div>
      <div class="panel col-7">
        <h3 class="mb-4">{{"REGISTER_PAGE.title" | translate}}</h3>
        <form [formGroup]="registerForm" (ngSubmit)="onRegister()">
          <div class="form-group">
            <label for="firstName">{{'FORM.label.first_name' | translate}}</label>
            <input id="firstName" type="text" formControlName="firstName" class="form-control custom"
                   placeholder="{{'FORM.placeholder.first_name' | translate}}"/>
            <div class="invalid-feedback" *ngIf="form.username.errors && isSubmittedForm">
              <span *ngIf="form.username.errors.required">{{"FORM.error.first_name_required" | translate}}</span>
            </div>
          </div>
          <div class="form-group">
            <label for="lastName">{{'FORM.label.last_name' | translate}}</label>
            <input id="lastName" type="text" formControlName="lastName" class="form-control custom"
                   placeholder="{{'FORM.placeholder.last_name' | translate}}"/>
            <div class="invalid-feedback" *ngIf="form.lastName.errors && isSubmittedForm">
              <span *ngIf="form.lastName.errors.required">{{"FORM.error.last_name_required" | translate}}</span>
            </div>
          </div>
          <div class="form-group">
            <label for="username">{{'FORM.label.username' | translate}}</label>
            <input id="username" type="text" formControlName="username" class="form-control custom"
                   placeholder="{{'FORM.placeholder.username' | translate}}"/>
            <div class="invalid-feedback" *ngIf="form.username.errors && isSubmittedForm">
              <span *ngIf="form.username.errors.required">{{"FORM.error.username_required" | translate}}</span>
            </div>
          </div>
          <div class="form-group" >
            <label for="email" [ngClass]="{'disabled': form.email.disabled}">{{'FORM.label.email' | translate}}</label>
            <input id="email" type="text" formControlName="email" class="form-control custom" [ngClass]="{'disabled': form.email.disabled}" />
          </div>
          <div class="form-group">
            <label for="password">{{'FORM.label.password' | translate}}</label>
            <input id="password" type="password" formControlName="password" class="form-control custom"
                   placeholder="{{'FORM.placeholder.password' | translate}}" autocomplete="off"/>
            <div class="invalid-feedback" *ngIf="form.password.errors && isSubmittedForm">
              <span *ngIf="form.password.errors.required">{{"FORM.error.password_required" | translate}}</span>
              <span *ngIf="form.password.errors.minlength">{{"FORM.password.password_min_length_6" | translate}}</span>
            </div>
          </div>
          <div class="form-group">
            <label for="confirmPassword">{{'FORM.label.confirm_password' | translate}}</label>
            <input id="confirmPassword" type="password" formControlName="confirmPassword" class="form-control custom"
                   placeholder="{{'FORM.placeholder.confirm_password' | translate}}" autocomplete="off"/>
            <div class="invalid-feedback" *ngIf="form.confirmPassword.errors && isSubmittedForm">
              <span *ngIf="form.confirmPassword.errors.required">{{"FORM.error.confirmed_password_required" | translate}}</span>
            </div>
            <div class="invalid-feedback" *ngIf="registerForm.errors?.pwdMismatch && isSubmittedForm">
              <span>{{"FORM.error.password_mismatch" | translate}}</span>
            </div>
          </div>
          <div class="alert alert-danger" role="alert" *ngIf="errorMessage" [innerHTML]="'FORM.server_error.' + errorMessage | translate">
          </div>
          <div class="form-group-btn">
            <button class="btn btn-primary">{{'FORM.button.register' | translate}}</button>
          </div>
          <div class="form-group-btn">
            <span>{{'REGISTER_PAGE.have_account' | translate}}</span><a routerLink="/login" class="btn-link"> {{"REGISTER_PAGE.login" | translate}}</a>
          </div>
        </form>
      </div>
      <div class="col-7 p-0">
        <div class="btn-group link-dropdown lang-dropdown" dropdown [dropup]="true" *ngIf="currentLanguage">
          <a id="language-dropdown" dropdownToggle type="button" class="dropdown-toggle">
            {{currentLanguage.display}} <span class="caret"></span>
          </a>
          <ul id="dropdown-custom-html" *dropdownMenu class="dropdown-menu"
              role="menu" aria-labelledby="language-dropdown">
            <li *ngFor="let lang of languages" role="menuitem"
                [ngClass]="{'active': currentLanguage.key === lang.key}"
                (click)="onChangeLanguage(lang)">{{lang.display}}
              <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                   viewBox="0 0 12.4 9.2" style="enable-background:new 0 0 12.4 9.2;" xml:space="preserve">
                <path d="M12.4,0.8c0,0.2-0.1,0.4-0.2,0.6L4.6,9C4.4,9.1,4.2,9.2,4,9.2C3.8,9.2,3.6,9.1,3.4,9L0.2,5.8c-0.3-0.3-0.3-0.9,0-1.2
                  c0.3-0.3,0.9-0.3,1.2,0L4,7.2l6.9-6.9c0.3-0.3,0.9-0.3,1.2,0C12.3,0.4,12.4,0.6,12.4,0.8z"/>
              </svg>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</perfect-scrollbar>


<div bsModal #registrationSuccessfulModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" >
  <div class="modal-dialog modal-sm alert-modal">
    <div class="modal-content">
      <button type="button" class="close pull-right" aria-label="Close" (click)="registrationSuccessfulModal.hide()">
        <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
             viewBox="0 0 16 16" style="enable-background:new 0 0 16 16;" xml:space="preserve">
          <path d="M15.7,14.6c0.3,0.3,0.3,0.8,0,1.2c-0.2,0.2-0.4,0.2-0.6,0.2c-0.2,0-0.4-0.1-0.6-0.2L8,9.1l-6.6,6.6C1.2,15.9,1,16,0.8,16
          c-0.2,0-0.4-0.1-0.6-0.2c-0.3-0.3-0.3-0.8,0-1.2L6.8,8L0.2,1.4c-0.3-0.3-0.3-0.8,0-1.2c0.3-0.3,0.8-0.3,1.2,0L8,6.8l6.6-6.6
          c0.3-0.3,0.8-0.3,1.2,0c0.3,0.3,0.3,0.8,0,1.2L9.1,8L15.7,14.6z"/>
        </svg>
      </button>
      <div class="modal-body text-center">
        <div class="icon-box">
          <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
               viewBox="0 0 37.9 27.8" style="enable-background:new 0 0 37.9 27.8;" xml:space="preserve">
                      <path d="M37.4,0.6c-0.7-0.7-1.9-0.7-2.7,0L12,23.3l-8.7-8.7c-0.7-0.7-1.9-0.7-2.7,0c-0.7,0.7-0.7,1.9,0,2.7l10.1,10.1
            c0.7,0.7,1.9,0.7,2.7,0L37.4,3.2C38.1,2.5,38.1,1.3,37.4,0.6z"/>
          </svg>
        </div>
        <h4>{{"REGISTER_PAGE.registration_successful" | translate}}</h4>
        <p>{{"REGISTER_PAGE.registration_successful_text" | translate}}</p>
        <div class="form-group-btn">
          <button type="submit" class="btn btn-primary" routerLink="/login">{{"FORM.button.login" | translate}}</button>
        </div>

      </div>
    </div>
  </div>
</div>

