import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { environment } from '../environments/environment';

import { AppComponent } from './app.component';
import { LoginComponent } from './guest/login/login.component';
import { AppRoutingModule } from './app-routing.module';
import { JwtModule } from '@auth0/angular-jwt';
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from '@angular/common/http';
import {ErrorInterceptor} from './shared/interceptors/error.interceptor';
import {RegisterComponent} from './guest/register/register.component';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {SharedModule} from './shared/modules/shared.module';
import {NgxWebstorageModule} from 'ngx-webstorage';
import {HttpOrgInterceptor} from './shared/interceptors/http-org.interceptor';
import {DecimalPipe} from '@angular/common';
import {AuthInterceptor} from './shared/interceptors/auth.interceptor';
import {ResetPasswordComponent} from './guest/reset-password/reset-password.component';
import {ForgotPasswordComponent} from './guest/forgot-password/forgot-password.component';

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    RegisterComponent,
    ResetPasswordComponent,
    ForgotPasswordComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    SharedModule,
    HttpClientModule,
    BrowserAnimationsModule,
    NgxWebstorageModule.forRoot({prefix: 'xtc', separator: '.', caseSensitive: true}),
    TranslateModule.forRoot({
      defaultLanguage: 'EN',
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      }
    }),
    JwtModule.forRoot({
      config: {
        tokenGetter: () => {
          const token = localStorage.getItem('xtc.accessToken');
          return token ? token.replace(/^"(.*)"$/, '$1') : null;
        },
        headerName: 'Authorization',
        allowedDomains: ['xplanis-api.azurewebsites.net', 'x4tarifapi.azurewebsites.net'],
        disallowedRoutes: []
      }
    }),
  ],
  providers: [
    DecimalPipe,
    {provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: HttpOrgInterceptor, multi: true},
  ],
  exports: [
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
