import {NgModule} from '@angular/core';
import { ModalModule } from 'ngx-bootstrap/modal';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import {ReactiveFormsModule} from '@angular/forms';
import {PerfectScrollbarModule} from 'ngx-perfect-scrollbar';
import {AccordionModule} from 'ngx-bootstrap/accordion';

@NgModule({
  declarations: [
  ],
  imports: [
    ReactiveFormsModule,
    BsDropdownModule.forRoot(),
    ModalModule.forRoot(),
    AccordionModule.forRoot(),
    PerfectScrollbarModule
  ],
  exports: [
    ReactiveFormsModule,
    BsDropdownModule,
    ModalModule,
    AccordionModule,
    PerfectScrollbarModule
  ]
})
export class SharedModule { }
