import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './guest/login/login.component';
import { RegisterComponent } from './guest/register/register.component';
import {AuthGuard} from './shared/guards/auth.guard';
import {GuestGuard} from './shared/guards/guest.guard';
import {ResetPasswordComponent} from './guest/reset-password/reset-password.component';
import {ForgotPasswordComponent} from './guest/forgot-password/forgot-password.component';

const routes: Routes = [
  { path: '', loadChildren: () => import('./user/user.module').then(m => m.UserModule), canActivate: [AuthGuard]},
  { path: 'login', component: LoginComponent, canActivate: [GuestGuard] },
  { path: 'reset-password', redirectTo: 'forgot-password', canActivate: [GuestGuard] },
  { path: 'reset-password/:token', component: ResetPasswordComponent, canActivate: [GuestGuard] },
  { path: 'register/:token', component: RegisterComponent, canActivate: [GuestGuard] },
  { path: 'forgot-password', component: ForgotPasswordComponent, canActivate: [GuestGuard] },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
