import {Component, OnInit, ViewChild} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {ResponseMessage} from '../../shared/interfaces/response-message';
import {AuthService} from '../../shared/services/auth.service';
import {LanguageService} from '../../shared/services/language.service';
import {Language} from '../../shared/interfaces/language';
import {ActivatedRoute, Router} from '@angular/router';
import {JwtHelperService} from '@auth0/angular-jwt';
import { ModalDirective } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {

  public emailForm: UntypedFormGroup;
  public errorMessage: string = null;
  public isSubmittedForm: boolean = false;

  public currentLanguage: Language = null;
  public languages: Language[] = null;


  @ViewChild('registrationSuccessfulModal', { static: false }) resetSuccessfulModal: ModalDirective;

  constructor(private authService: AuthService,
              private route: ActivatedRoute,
              private router: Router,
              private jwtHelperService: JwtHelperService,
              private languageService: LanguageService) {
  }

  ngOnInit(): void {
    this.getLanguages();

    this.createEmailForm();
  }

  public onChangeLanguage(lang: Language): void {
    this.currentLanguage = lang;
    this.languageService.currentLanguage = lang;
  }

  public get form() { return this.emailForm.controls; }

  public onSubmit(): void {
    this.isSubmittedForm = true;

    if (this.emailForm.invalid) {
      return;
    }
    this.authService.requestResetPassword(this.emailForm.value.email)
      .subscribe(
        () => {
          this.resetSuccessfulModal.show();
        },
        (error: ResponseMessage) => {
          this.errorMessage = error.key;
        }
      );
  }

  private createEmailForm(): void {
    this.emailForm = new UntypedFormGroup({
      email: new UntypedFormControl('', [Validators.required, Validators.email]),
    });
  }


  private getLanguages() {
    this.languageService.getLanguages()
      .subscribe(res => {
        this.languages = this.languageService.languages;
        this.currentLanguage = this.languageService.currentLanguage;
      });
  }
}
