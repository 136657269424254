import {Component, OnInit, ViewChild} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {ResponseMessage} from '../../shared/interfaces/response-message';
import {pwdMatchValidator} from '../../shared/validators/pwd-match.validator';
import {AuthService} from '../../shared/services/auth.service';
import {LanguageService} from '../../shared/services/language.service';
import {Language} from '../../shared/interfaces/language';
import {ActivatedRoute, Router} from '@angular/router';
import {JwtHelperService} from '@auth0/angular-jwt';
import { ModalDirective } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {

  public token: string = null;
  public registerForm: UntypedFormGroup;
  public errorMessage: string = null;
  public isSubmittedForm: boolean = false;
  public invitedEmail: string = null;

  public currentLanguage: Language = null;
  public languages: Language[] = null;


  @ViewChild('registrationSuccessfulModal', { static: false }) registrationSuccessfulModal: ModalDirective;

  constructor(private authService: AuthService,
              private route: ActivatedRoute,
              private router: Router,
              private jwtHelperService: JwtHelperService,
              private languageService: LanguageService) {
  }

  ngOnInit(): void {
    this.getLanguages();
    this.token = this.route.snapshot.paramMap.get('token');

    try{
      this.invitedEmail = this.jwtHelperService.decodeToken(this.token).email;
    } catch (e) {
      this.router.navigate(['/login']);
    }

    this.createRegisterForm();
  }

  public onChangeLanguage(lang: Language): void {
    this.currentLanguage = lang;
    this.languageService.currentLanguage = lang;
  }

  public get form() { return this.registerForm.controls; }

  public onRegister(): void {
    this.isSubmittedForm = true;

    if (this.registerForm.invalid) {
      return;
    }
    this.authService.register(this.registerForm.value, this.token)
      .subscribe(
        () => {
          this.registrationSuccessfulModal.show();
        },
        (error: ResponseMessage) => {
          this.errorMessage = error.key;
        }
      );
  }


  private createRegisterForm(): void {
    this.registerForm = new UntypedFormGroup({
      firstName: new UntypedFormControl('', Validators.required),
      lastName: new UntypedFormControl('', Validators.required),
      username: new UntypedFormControl('', Validators.required),
      email: new UntypedFormControl({value: this.invitedEmail, disabled: true}, [Validators.required]),
      password: new UntypedFormControl('', [Validators.required, Validators.minLength(6)]),
      confirmPassword: new UntypedFormControl('', Validators.required)
    }, [pwdMatchValidator]);
  }


  private getLanguages() {
    this.languageService.getLanguages()
      .subscribe(res => {
        this.languages = this.languageService.languages;
        this.currentLanguage = this.languageService.currentLanguage;
      });
  }
}
