import {Component, OnInit, ViewChild} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {ResponseMessage} from '../../shared/interfaces/response-message';
import {pwdMatchValidator} from '../../shared/validators/pwd-match.validator';
import {AuthService} from '../../shared/services/auth.service';
import {LanguageService} from '../../shared/services/language.service';
import {Language} from '../../shared/interfaces/language';
import {ActivatedRoute, Router} from '@angular/router';
import {JwtHelperService} from '@auth0/angular-jwt';
import { ModalDirective } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {

  public token: string = null;
  public resetPasswordForm: UntypedFormGroup;
  public errorMessage: string = null;
  public isSubmittedForm: boolean = false;
  public email: string = '';

  public currentLanguage: Language = null;
  public languages: Language[] = null;


  @ViewChild('registrationSuccessfulModal', { static: false }) resetSuccessfulModal: ModalDirective;

  constructor(private authService: AuthService,
              private route: ActivatedRoute,
              private router: Router,
              private jwtHelperService: JwtHelperService,
              private languageService: LanguageService) {
  }

  ngOnInit(): void {
    this.getLanguages();
    this.token = this.route.snapshot.paramMap.get('token');
    this.email = this.route.snapshot.queryParamMap.get('email');
    if (!this.email || !this.token){
      this.router.navigate(['/forgot-password']);
    }

    this.createResetPasswordForm();
  }

  public onChangeLanguage(lang: Language): void {
    this.currentLanguage = lang;
    this.languageService.currentLanguage = lang;
  }

  public get form() { return this.resetPasswordForm.controls; }

  public onReset(): void {
    this.isSubmittedForm = true;

    if (this.resetPasswordForm.invalid) {
      return;
    }
    this.authService.resetPassword(this.resetPasswordForm.value.password, this.email, this.token)
      .subscribe(
        () => {
          this.resetSuccessfulModal.show();
        },
        (error: ResponseMessage) => {
          this.errorMessage = error.key;
        }
      );
  }


  private createResetPasswordForm(): void {
    this.resetPasswordForm = new UntypedFormGroup({
      password: new UntypedFormControl('', [Validators.required, Validators.minLength(6)]),
      confirmPassword: new UntypedFormControl('', Validators.required)
    }, [pwdMatchValidator]);
  }


  private getLanguages() {
    this.languageService.getLanguages()
      .subscribe(res => {
        this.languages = this.languageService.languages;
        this.currentLanguage = this.languageService.currentLanguage;
      });
  }
}
