import { Component, OnInit } from '@angular/core';
import {AuthService} from '../../shared/services/auth.service';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {ResponseMessage} from '../../shared/interfaces/response-message';
import {Language} from '../../shared/interfaces/language';
import {LanguageService} from '../../shared/services/language.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  public loginForm: UntypedFormGroup;
  public errorMessage: string = null;
  public isSubmittedForm: boolean = false;

  public currentLanguage: Language = null;
  public languages: Language[] = null;

  constructor(private authService: AuthService,
              private languageService: LanguageService,
              private router: Router) { }

  ngOnInit(): void {
    this.getLanguages();
    this.createLoginForm();
  }

  public onChangeLanguage(lang: Language): void {
    this.currentLanguage = lang;
    this.languageService.currentLanguage = lang;
  }

  public get form() { return this.loginForm.controls; }

  public onLogin(): void {
    this.isSubmittedForm = true;

    if (this.loginForm.invalid) {
      return;
    }

    this.authService.login(this.loginForm.value)
      .subscribe(
        (res) => {
          if (res.role === 'SYS_ADMIN') {
            this.router.navigateByUrl('/content-management');
          } else {
            this.router.navigateByUrl('/tariff-schedule');
          }
        },
        (error: ResponseMessage) => {
          this.errorMessage = error.key;
        }
      );
  }

  protected createLoginForm() {
    this.loginForm = new UntypedFormGroup({
      username: new UntypedFormControl('', Validators.required),
      password: new UntypedFormControl('', Validators.required),
    });
  }

  private getLanguages() {
    this.languageService.getLanguages()
      .subscribe(res => {
        this.languages = this.languageService.languages;
        this.currentLanguage = this.languageService.currentLanguage;
      });
  }
}
