<div class="container">
  <div class="row justify-content-center ">
    <div class="col-7 p-0 text-center logo">
      <img src="../../../assets/images/logo-full.png" width="241" height="80">
    </div>
    <div class="panel col-7">
      <h3 class="mb-4">{{'LOGIN_PAGE.title' | translate}}</h3>
      <form [formGroup]="loginForm" (ngSubmit)="onLogin()">
        <div class="form-group">
          <label for="username">{{'FORM.label.username' | translate}}</label>
          <input id="username" type="text" formControlName="username" class="form-control custom"
                 [placeholder]="'FORM.placeholder.username' | translate"/>
          <div class="invalid-feedback" *ngIf="form.username.errors && isSubmittedForm">
            <span *ngIf="form.username.errors.required">{{'FORM.error.username_required' | translate}}</span>
          </div>
        </div>
        <div class="form-group">
          <label for="password">{{'FORM.label.password' | translate}}</label>
          <input id="password" type="password" formControlName="password" class="form-control custom"
                 [placeholder]="'FORM.placeholder.password' | translate" autocomplete="off"/>
          <div class="invalid-feedback" *ngIf="form.password.errors && isSubmittedForm">
            <span *ngIf="form.password.errors.required">{{'FORM.error.password_required' | translate}}</span>
          </div>
        </div>
        <div class="alert alert-danger" role="alert" *ngIf="errorMessage"  [innerHTML]="'FORM.server_error.' + errorMessage | translate">

        </div>
        <div class="form-group-btn d-flex align-items-center">
          <button class="btn btn-primary">{{'FORM.button.login' | translate}}</button>
          <a class="btn-link ml-auto d-inline-block" routerLink="/forgot-password">{{"LOGIN_PAGE.forgot_password" | translate}}</a>
        </div>
      </form>
    </div>
    <div class="col-7 p-0">
      <div class="btn-group link-dropdown lang-dropdown" dropdown [dropup]="true" *ngIf="currentLanguage">
        <a id="language-dropdown" dropdownToggle type="button" class="dropdown-toggle">
          {{currentLanguage.display}} <span class="caret"></span>
        </a>
        <ul id="dropdown-custom-html" *dropdownMenu class="dropdown-menu"
            role="menu" aria-labelledby="language-dropdown">
          <li *ngFor="let lang of languages" role="menuitem"
              [ngClass]="{'active': currentLanguage.key === lang.key}"
              (click)="onChangeLanguage(lang)">{{lang.display}}
            <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                 viewBox="0 0 12.4 9.2" style="enable-background:new 0 0 12.4 9.2;" xml:space="preserve">
              <path d="M12.4,0.8c0,0.2-0.1,0.4-0.2,0.6L4.6,9C4.4,9.1,4.2,9.2,4,9.2C3.8,9.2,3.6,9.1,3.4,9L0.2,5.8c-0.3-0.3-0.3-0.9,0-1.2
                c0.3-0.3,0.9-0.3,1.2,0L4,7.2l6.9-6.9c0.3-0.3,0.9-0.3,1.2,0C12.3,0.4,12.4,0.6,12.4,0.8z"/>
            </svg>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
