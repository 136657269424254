import { Injectable } from '@angular/core';
import {Observable, of} from 'rxjs';
import {environment} from '../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {Organization} from '../interfaces/organization';
import {AuthService} from './auth.service';
import {LocalStorageService} from 'ngx-webstorage';
import {map} from 'rxjs/operators';
import {notificationMessage, NotificationService} from './notification.service';

@Injectable({
  providedIn: 'root'
})
export class OrganizationService {

  public get organization(): Organization {
    return this.localStorage.retrieve('organization') ?? null;
  }
  public set organization(organization: Organization) {
    this.localStorage.store('organization', organization);
  }

  public unsetOrganization(): void {
    this.localStorage.clear('organization');
  }

  constructor(private http: HttpClient,
              private localStorage: LocalStorageService,
              private notificationService: NotificationService,
              private authService: AuthService) { }

  getAll(): Observable<Organization[]> {
    if (this.authService.user.role !== 'SYS_ADMIN'){
      return of(null);
    }
    return this.http.get<Organization[]>(`${environment.apiUrl}/Organizations`);
  }

  getOrganization(updateOrg?: boolean): Observable<Organization> {

    if (!updateOrg && this.organization) {
      return of(this.organization);
    }

    return this.http.get<Organization>(`${environment.apiUrl}/Organizations/Info`).pipe(
      map(res => {
        this.organization = res;
        return res;
      })
    );
  }

  create(name: string): Observable<Organization> {
    return this.http.post<Organization>(`${environment.apiUrl}/Organizations`, {name});
  }

  update(organization: Organization): Observable<Organization> {
    return this.http.put<Organization>(`${environment.apiUrl}/Organizations`, organization).pipe(
      map(res => {
        this.organization = res;
        return res;
      })
    );
  }

  uploadLogo(base64): Observable<any> {
    const formData = new FormData();
    formData.append('file', this.convertBase64ToFile(base64, 'logo.jpg'));
    return this.http.put<Organization>(`${environment.apiUrl}/Organizations/Upload`, formData)
      .pipe(map( x => {
        this.notificationService.sendMessage(notificationMessage.changeOrgLogo);
        return x;
      }));
  }

  private convertBase64ToFile(base64, filename): File {
    const arr = base64.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  }
}

