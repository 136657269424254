import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import {Router} from '@angular/router';
import {AuthService} from '../services/auth.service';


@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

  constructor(private router: Router,
              private authService: AuthService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(catchError(err => {
      let error = null;
      if ([401].indexOf(err.status) !== -1) {
        this.authService.logout();
        this.router.navigateByUrl('/login');
        return;
      } else if ([405].indexOf(err.status) !== -1) {
        error = {
          key: 'method_not_allowed',
          message: 'Method Not Allowed'
        };
      } else {
        error = err.error;
      }

      return throwError(error);
    }));
  }
}
