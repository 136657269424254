import { Injectable } from '@angular/core';
import {LocalStorageService, SessionStorageService} from 'ngx-webstorage';
import {TranslateService} from '@ngx-translate/core';
import {Language} from '../interfaces/language';
import {Observable, of} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class LanguageService {

  constructor(private localStorage: LocalStorageService,
              private sessionStorage: SessionStorageService,
              private http: HttpClient,
              private translateService: TranslateService) {
    if (this.languages) {
      this.translateService.addLangs(this.languages.map(x => x.key));
      this.translateService.use(this.currentLanguage.key);
    }
  }

  public get currentLanguage(): Language {
    return this.localStorage.retrieve('currentLanguage') ?? this.languages[0];
  }

  public set currentLanguage(lang: Language) {
    this.translateService.use(lang.key);
    this.localStorage.store('currentLanguage', lang);
  }

  public get languages(): Language[] {
    return this.sessionStorage.retrieve('languages') ?? null;
  }

  public set languages(lang: Language[]) {
    this.sessionStorage.store('languages', lang);
  }


  public getLanguages(): Observable<Language[]> {

    if (this.languages) {
      return of(this.languages);
    }

    return this.http.get<Language[]>(`${environment.apiUrl}/Global/Languages`).pipe(
      map(res => {
        this.languages = res;
        this.translateService.addLangs(this.languages.map( x => x.key));
        this.translateService.use(this.currentLanguage.key);
        return res;
      })
    );
  }

}
