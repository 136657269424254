import { Injectable } from '@angular/core';
import {Observable, Subject} from 'rxjs';

export const notificationMessage = {
  login: 1,
  logout: 2,
  changedOrg: 3,
  changedLang: 4,
  changeOrgLogo: 5,
};


@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  public notificationMessageSubject: Subject<number>;
  public notificationMessageObservable: Observable<number>;

  constructor() {
    this.notificationMessageSubject = new Subject<number>();
    this.notificationMessageObservable = this.notificationMessageSubject.asObservable();
  }

  public sendMessage(message: number): void {
    this.notificationMessageSubject.next(message);
  }

  public getMessage(): Observable<number> {
    return this.notificationMessageSubject.asObservable();
  }
}
