import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';
import {AuthService} from '../services/auth.service';
import {OrganizationService} from '../services/organization.service';

@Injectable()
export class HttpOrgInterceptor implements HttpInterceptor {

  constructor(private authService: AuthService,
              private organizationService: OrganizationService) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {

    let params = request.params;


    const whitelistPath = [
      'Users',
      'Data/Master/Schema',
      'Data/Schema',
      'Data/1/Schema',
      'Data/Value',
      'Data/User/Schema',
      'TariffTemplates',
      'TariffSchedules',
      'TariffSchedules/Simple',
      'TariffModel',
      'Exchange',
    ];

    const isAllowed = whitelistPath.some(x => request.url.includes(x));

    if (isAllowed && this.authService.user.role === 'SYS_ADMIN' && this.organizationService.organization) {
      const org = this.organizationService.organization;
      params = params.append('organizationId', org.id.toString());
      return next.handle(request.clone({params}));
    }

    return next.handle(request);
  }
}
